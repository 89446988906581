import {
  Box,
  Center,
  Flex,
  GridItem,
  HStack,
  SimpleGrid,
  Text,
  useMultiStyleConfig,
} from "@chakra-ui/react";
import { ButtonNavigate, ButtonNavigateUrl } from "../ui/FormControls";

import { Switch, useColorMode } from "@chakra-ui/react";
import { latestVersion } from "../../pages/versioning/ReleaseLog";

const Footer: React.FC = () => {
  const stylesFloat = useMultiStyleConfig("Footer", {});

  return (
    <Flex __css={{ ...stylesFloat.main }}>
      <SimpleGrid w="full%" columns={[1, 1, 3, 3]}>
        <GridItem>
          <HStack
            w="full"
            justifyContent={["center", "center", "left", "left"]}
          >
            <ButtonNavigate route="/coaches" variant="link">
              Find a Coach
            </ButtonNavigate>{" "}
            <Text>|</Text>
            <ButtonNavigateUrl
              url="https://www.swimsmooth.com/help"
              variant="link"
            >
              Support
            </ButtonNavigateUrl>{" "}
            <Text>|</Text>
            <ButtonNavigateUrl
              url="https://app.supademo.com/showcase/cm2x24i8109z9krgpsxfom8n1?demo=1&step=3"
              variant="link"
            >
              User Guides
            </ButtonNavigateUrl>{" "}
            <Text>|</Text>
            <ButtonNavigate route="/pricing" variant="link">
              Pricing
            </ButtonNavigate>{" "}
          </HStack>
        </GridItem>
        <GridItem>
          <Center>
            <ThemeToggle />
          </Center>
        </GridItem>
        <GridItem>
          <HStack
            w="full"
            justifyContent={["center", "center", "right", "right"]}
          >
            <ButtonNavigate route="/releases/log" variant="link">
              {latestVersion}
            </ButtonNavigate>{" "}
            <Text>|</Text>
            <ButtonNavigate route="/terms" variant="link">
              Terms of Service
            </ButtonNavigate>{" "}
            <Text>|</Text>
            <ButtonNavigate route="/privacy" variant="link">
              Privacy Policy
            </ButtonNavigate>
          </HStack>
        </GridItem>
        <GridItem colSpan={[1, 1, 3, 3]}>
          <Text fontSize={"xs"} align={"center"}>
            &copy; Swim Smooth Limited 2004 - {new Date().getFullYear()}
          </Text>
        </GridItem>
      </SimpleGrid>
    </Flex>
  );
};

const ThemeToggle = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box display="inline-flex" alignItems="center">
      <Text mr={4}>Light Mode</Text>
      <Switch
        size={{ base: "sm", sm: "md" }}
        isChecked={colorMode === "dark"}
        onChange={toggleColorMode}
        colorScheme="pink"
      />
      <Text ml={4}>Dark Mode</Text>
    </Box>
  );
};

export { Footer };
